//コーポレートカラー
$corp_color: #f07700;
$corp_color_pale: #fca518;
$corp_color_dark: #DF1F1C;
$hug_house_color: #FFFBD1;

//ページ文幅
$page_width: 950px;

//本文幅
$main_width: 665px;

//Webフォント
//$font-shingo-r: "ShinGoPro-Regular", "新ゴ R", "Shin Go Regular", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//$font-jun-201: "Jun201Pro-Regular", "じゅん 201", "Jun 201", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-shingo-r: "新ゴ R", "Shin Go Regular", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$font-jun-201: "じゅん 201", "Jun 201", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

//高解像度2xメディアクエリ
@mixin m-mq2x() {
	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
		@content;
	}
}



/**
 * 初期化
 */

html, body, h1, h2, h3, h4, h5, h6, p, a, address, img, figure,
ul, ol, li, dl, dt, dd, table, tr, td {
	margin: 0;
	padding: 0;
	border: 0;
}

html {
	font-size: 62.5%;
}

body {
	font: {
		family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
		size: 1.4rem;
	}
	line-height: 2;
	color: #222;
}

h1, h2, h3, h4, h5, h6, th {
	font-weight: normal;
}

a:link, a:visited, a:active, a:hover {
	text-decoration: none;
	color: $corp_color_pale;
}

address {
	font-style: normal;
}

img {
	border-style: none;
}

ol, ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}



/**
 * フォーム初期化
 */

input[type="text"], input[type="date"],
input[type="datetime"], input[type="email"],
input[type="number"], input[type="search"],
input[type="time"], input[type="url"],
textarea, select {
	transition: all 0.30s ease-in-out;
	box-sizing: border-box;
	background: #fff;
	margin-bottom: 15px;
	padding: 7px;
	outline: none;
	border: 1px solid #ccc;
	font-family: $font_jun_201;
	font-size: 1.4rem;
}

input[type="text"]:focus, input[type="date"]:focus,
input[type="datetime"]:focus, input[type="email"]:focus,
input[type="number"]:focus, input[type="search"]:focus,
input[type="time"]:focus, input[type="url"]:focus,
textarea:focus, select:focus {
	padding: 7px;
	border: 1px solid $corp_color_pale;
	box-shadow: 0 0 5px $corp_color_pale;
}

input[type="submit"], input[type="button"] {
	box-sizing: border-box;
	padding: 7px;
	background: $corp_color;
	border-style: none;
	font-family: $font_jun_201;
	font-size: 1.8rem;
	color: #fff;
	cursor: pointer;
}

input[type="submit"]:hover, input[type="button"]:hover {
	background: $corp_color_pale;
}



/**
 * ヘッダー
 */

.globalHeader {
	border-top: 5px solid $corp_color;
}

.globalHeader-ci {
	position: relative;
	width: $page_width;
	height: 145px;
	margin: 0 auto;
	padding-top: 50px;
}

.globalHeader-ci-title {
	width: 260px;
	margin: 0 auto;
	text-align: center;
}

.globalHeader-ci-title-link {
	display: block;
	line-height: 0;
}

.globalHeader-ci-contact {
	position: absolute;
	width: 227px;
	top: 50px;
	right: 0;
}

.globalHeader-ci-contact-tel {
	margin-top: 40px;
	line-height: 0;
}

.globalHeader-ci-contact-hugclub {
	display: block;
	text-align: right;
	line-height: 0;
}

.globalHeader-nav {
	background: linear-gradient(180deg, rgba(248,154,39,1) 0%, rgba(240,119,0,1) 100%);
	border-bottom: 3px solid $corp_color_dark;
	
	&::after {
		display: block;
		content: "";
		height: 1px;
		background: #FAAE52;
	}
}

.globalHeader-nav-list {
	display: flex;
	align-items: flex-start;
	width: $page_width;
	margin: 0 auto;
}

.globalHeader-nav-list-item {
	$border_style: 1px solid #FFDBB1;
	$width: 239px;

	width: $width - 1px;
	border-right: $border_style;
	
	&:first-child {
		width: $width - 1px;
		border-left: $border_style;
	}
}

.globalHeader-nav-list-item-link {
	display: block;
	height: 44px;
	padding-top: 16px;
	text-align: center;
	line-height: 0;
}



/**
 * パンくずナビ
 */

.locus {
	border-bottom: 1px solid #ccc;
}

.locus-list {
	display: flex;
	align-items: flex-start;
	width: $page_width;
	margin: 0 auto;
}

.locus-list-item {
	height: 48px;
	padding: 0 10px 0 27px;
	line-height: 50px;
	background: url(../images/header/locus_bg.svg) no-repeat;

	&:first-child {
		padding-left: 0;
		background: none;
	}
}

.locus-list-item-link {
	display: block;
}

.locus-list-item-link.home {
	display: flex;
	align-items: center;

	&::before {
		display: inline-block;
		content: "";
		width: 17px;
		height: 17px;
		padding: 0 10px 5px 0;
		background: url(../images/header/locus_home.svg) no-repeat;
	}
}



/**
 * ページ上部へ戻る
 */

.pageTop {
	background: $corp_color;
	border-bottom: 15px solid #f1e6d4;
}

.pageTop-link {
	display: block;
	height: 26px;
	padding-top: 12px;
	text-align: center;
	line-height: 0;
}



/**
 * フッター
 */

.globalFooter-companyInfo {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: $page_width;
	height: 78px;
	margin: 0 auto;
}

.globalFooter-companyInfo-title {
	width: 300px;
	font-size: 2rem;
	font-family: $font-shingo-r;
}

.globalFooter-companyInfo-addr {
	width: 650px;
	font-size: 1.2rem;
	text-align: right;
}

.globalFooter-copyright {
	display: block;
	width: $page_width;
	height: 54px;
	margin: 0 auto 50px;
	background: url(../images/footer/bg.png) 0 -78px no-repeat;
	font-family: arial, helvetica, sans-serif;
	font-size: 1.2rem;
	letter-spacing: 0.2em;
	text-align: center;
	line-height: 54px;

	@include m-mq2x() {
		background: url(../images/footer/bg@2x.png) 0 -78px no-repeat;
		background-size: $page_width;
	}
}



/**
 * スライダー（トップページのみ）
 */

 .slider {
	height: 350px;
	border-bottom: 5px solid $corp_color;
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,247,239,1) 100%);
}

.swiper-container {
	width: $page_width - 20px;
	margin: 20px auto 0;
	line-height: 0;
}



/**
 * アイキャッチ画像（サブページ全般）
 */

.eyecatch {
	border-bottom: 5px solid $corp_color;
	background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,247,239,1) 100%);
}

.eyecatch-inner {
	display: flex;
	width: $page_width;
	margin: 0 auto;
}

.eyecatch-inner-title {
	box-sizing: border-box;
	width: 390px;
	height: 200px;
	padding: 50px 0 0 30px;
	background: linear-gradient(135deg, rgba(240,92,0,1) 0%, rgba(248,154,39,1) 100%);
}

.eyecatch-inner-title.hughouse {
	padding: 20px 115px 0;
	background: $hug_house_color;
}

.eyecatch-inner-image {
	display: block;
	align-self: flex-start;
}



/**
 * 本文
 */

 .ctr {
	display: flex;
	width: $page_width;
	margin: 0 auto;
}



/**
 * トップページ
 */

.ctr.index {
	margin-top: 50px;
	margin-bottom: 50px;

	.ctr-body {
		width: 400px;
		margin-right: 50px;
	}

	.ctr-body-newsTitle {
		margin-bottom: 30px;
		line-height: 1;

		&::before {
			display: block;
			content: "";
			height: 50px;
			margin-left: 15px;
			background: #fff url(../images/index/news_title.svg) no-repeat;
		}
	}

	.ctr-body-newsTitle-inner {
		display: block;
		padding: 8px 10px 6px;
		background: $corp_color_pale;
		color: #fff;
	}

	.ctr-body-newsList-title {
		display: flex;
		align-items: center;
		margin: 25px 0 10px;
		line-height: 1;

		&:first-child {
			margin-top: 0;
		}

		&::after {
			display: inline-block;
			content: "";
			width: 83px;
			height: 15px;
			margin-left: 15px;
			padding-bottom: 2px;
			background-repeat: no-repeat;
		}

		&.news::after {
			background-image: url(../images/index/cat_news.svg);
		}

		&.event::after {
			background-image: url(../images/index/cat_event.svg);
		}
	}

	.ctr-body-newsList-item {
		margin-top: 10px;
		padding-bottom: 20px;
		border-bottom: 1px solid #ccc;
		line-height: 1.5;

		&:last-child {
			padding-bottom: 0;
			border-bottom: none;
		}
	}

	.ctr-concept {
		width: 500px;
		margin: 30px 0 50px;
	}

	.ctr-concept-sect {
		display: flex;
		align-items: center;
	}

	.ctr-concept-sect-body-title {
		margin-bottom: 10px;
		line-height: 0;
	}

	// ランニングコスト装飾クラス
	.ctr-concept-sect.runningCost {
		flex-direction: row-reverse;
		margin-bottom: 35px;

		.ctr-concept-sect-body-content {
			font-size: 1.2rem;
			line-height: 1.5;
		}

		.ctr-concept-sect-image {
			margin-right: 14px;
		}
	}

	// 家づくりポリシー装飾クラス
	.ctr-concept-sect.method {
		.ctr-concept-sect-body-content {
			margin-left: 18px;
		}

		.ctr-concept-sect-image {
			margin-left: 16px;
		}
	}
}



/**
 * サブページ共通
 */

.ctr.sub {
	flex-wrap: wrap;
	flex-direction: row-reverse;
	margin-top: 70px;
	margin-bottom: 50px;
	border: 1px solid #ccc;
	background: #FAF3E5;

	.ctr-title {
		width: $page_width;
		height: 90px;
		padding-left: 30px;
		background: #fff url(../images/sub/sub_title_bg.jpg) right top no-repeat;
		font-family: $font-shingo-r;
		font-size: 2.6rem;
		line-height: 90px;
		color: $corp_color;

		@include m-mq2x() {
			background: url(../images/sub/sub_title_bg@2x.png) right top no-repeat;
		}
	}

	.ctr-body {
		position: relative;
		width: $main_width;
		margin: 15px;
		padding: 30px 40px;
		background: #fff;
	}

	.ctr-body-title {
		display: flex;
		align-items: center;
		margin: 10px -40px 25px;
		padding: 0 40px 15px;
		border-bottom: 1px solid #ccc;
		font-size: 2rem;

		&::before {
			display: block;
			content: "";
			width: 7px;
			height: 28px;
			margin: 0 15px 2px 0;
			background: $corp_color_dark;
		}
	}

	.ctr-body-imageWrap {
		margin: 50px 0;
	}

	.ctr-body-imageWrap.center {
		text-align: center;
	}

	.ctr-body-imageWrap.last {
		margin-bottom: 0;
	}

	.ctr-body-defList {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin: 30px 0;
	}

	.ctr-body-defList-title {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		width: 165px;
		padding: 10px 0 7px 15px;
		border-top: 1px solid #ccc;
		line-height: 1.5;
		color: $corp_color;

		&::before {
			display: block;
			content: "";
			width: 6px;
			height: 18px;
			margin: 0 10px 2px 0;
			background: $corp_color_pale;
		}
	}

	.ctr-body-defList-item {
		box-sizing: border-box;
		width: 500px;
		padding: 10px 3px 7px 0;
		border-top: 1px solid #ccc;
		line-height: 1.5;
	}

	.ctr-body-defList-title:first-child,
	.ctr-body-defList-title:first-child + .ctr-body-defList-item {
		border-top: none;
	}

	.ctr-body-buttonHome {
		position: absolute;
		top: 0;
		right: 0;
		width: 130px;
		background: $corp_color_pale;
	}

	.ctr-body-buttonHome-link {
		display: block;
		line-height: 0;
		padding: 10px 0;
		text-align: center;
	}

	.ctr-sidebar {
		width: 160px;
		margin: 15px 0;
		background: $corp_color_pale;
	}

	.ctr-sidebar-title {
		padding: 50px 10px 10px;
		opacity: 0.5;
	}

	.ctr-sidebar-title-image {
		display: block;
		margin-left: auto;
		line-height: 0;
	}

	.ctr-sidebar-list {
		padding: 10px 0;
		background: #FAF3E5;
	}

	.ctr-sidebar-list-item {
		padding: 1.2rem 0.5rem 1rem;
		border-bottom: 1px solid #ccc;
		font-size: 1.3rem;
		line-height: 1.5;

		&:last-child {
			border-bottom: none;
		}
	}

	.ctr-sidebar-list-item-link {
		color: $corp_color_dark;
	}
}



/**
 * サブページ（いいことも、悪いことも正直に）
 */

.ctr.sub.policy {
	.ctr-body-imageWrap {
		position: relative;
		height: 318px;
	}

	.ctr-body-imageWrap-desk {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 55px;
	}

	.ctr-body-imageWrap-bedroom {
		position: absolute;
		z-index: 1;
		top: 100px;
		right: 55px;
	}

	.ctr-body-imageWrap-ci {
		position: absolute;
		z-index: 3;
		top: 105px;
		left: 25px;
	}
}



/**
 * サブページ（ハグハウス）
 */

 .ctr.sub.hughouse {
	.ctr-body {
		position: relative;
	}

	.ctr-body-title {
		display: block;
		margin: 65px 0 80px;
		padding: 0;
		border-bottom: none;
		text-align: center;

		&::before {
			content: none;
		}
	}

	.ctr-body-note {
		position: absolute;
		top: 250px;
		right: 30px;
		width: 185px;
		font-size: 1.3rem;
		line-height: 1.5;

	}

	.ctr-body-lineup {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.ctr-body-lineup-item {
		width: 160px;
		text-align: center;
	}

	.ctr-body-lineup-item.large {
		width: 200px;
	}

	.ctr-body-lineup-catch {
		margin: 30px 0;
	}

	.ctr-sidebar {
		background: $hug_house_color;
	}
 }



/**
 * サブページ（会社概要）
 */

 .ctr.sub.profile {
 	.ctr-body-map {
		 margin: 50px -40px 0;
		 border: none;
	 }
 }



/**
 * サブページ（お問い合わせ）
 */

 .ctr.sub.contact {
	.ctr-body-mailform {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin: 30px 0 0;
	}

	.ctr-body-mailform-item {
		width: $main_width;
	}

	.ctr-body-mailform-item.inquiryType {
		width: 335px;
		margin-right: 330px;
	}

	.ctr-body-mailform-item.name {
		width: 335px;
		margin-right: 10px;
	}

	.ctr-body-mailform-item.ruby {
		width: $main_width - 345px;
	}

	.ctr-body-mailform-item.zip {
		width: 150px;
		margin-right: 10px;
	}

	.ctr-body-mailform-item.addr {
		width: $main_width - 160px;
	}

	.ctr-body-mailform-item.inquiry {
		height: 150px;
	}

	.ctr-body-mailform-confirmButton {
		width: $main_width;
	}

	.ctr-body-confirm {
		margin: 30px 0 15px;
		font-family: $font_jun_201;
	}

	.ctr-body-confirm-title {
		box-sizing: border-box;
		width: 235px;
		padding: 7px;
		border: 1px solid #ccc;
		background: $hug_house_color;
		text-align: left;
	}

	.ctr-body-confirm-item {
		box-sizing: border-box;
		width: $main_width - 235px;
		padding: 7px;
		border: 1px solid #ccc;
	}

	.ctr-body-confirmButtonCtr {
		display: flex;
	}

	.ctr-body-confirmButtonCtr-sendButton {
		width: 400px;
		margin-right: 10px;
	}

	.ctr-body-confirmButtonCtr-modifyButton {
		width: $main_width - 410px;
	}
}